'use client'
import { useContext } from 'react'

import { delay } from '@/lib/helper'
import { useNavigationEvent } from '@/hooks/useNavigationEvent'

import { PageProgressContext } from '@/providers/PageProgressContext'

function PageLoadingIndicator() {
  const { progressBar } = useContext(PageProgressContext)

  useNavigationEvent(() => {
    delay(1000).finally(progressBar.finish)
  })

  return null
}

export default PageLoadingIndicator
