import type { Analytics } from 'firebase/analytics'
import { getAnalytics, getGoogleAnalyticsClientId, logEvent, setUserId } from 'firebase/analytics'
import { FirebaseApp } from 'firebase/app'
import normalize from 'normalize-object'

import { createFirebaseApp } from '@/lib/firebase'

import { TrackableEvent, User } from '@/types'

export default class EventTracking {
  app: FirebaseApp | null
  analytics: Analytics | null
  user: User | null
  fid: string | null

  constructor() {
    this.app = null
    this.analytics = null
    this.user = null
    this.fid = null

    if (typeof window !== 'undefined') {
      this.app = createFirebaseApp()
      if (this.app) {
        this.analytics = getAnalytics(this.app)
      }
    }
  }

  async getFID() {
    if (this.fid) {
      return this.fid
    }

    if (typeof window !== 'undefined' && this.app && this.analytics) {
      try {
        // const installation = getInstallations(this.app)
        const fid = await getGoogleAnalyticsClientId(this.analytics)
        this.fid = fid
        return this.fid
      } catch (e) {
        console.log(`firebase installation id failed`, e)
      }
    }

    return null
  }

  identify(user: User) {
    if (typeof window === 'undefined') {
      return
    }

    if (!user) {
      return
    }

    const info = {
      Name: `${user.firstname} ${user.lastname}`,
      Identity: user.id,
      Email: user.email,
      Phone: user.phone,
      // city: user?.city,
      // state: user?.state,
      // country: user?.country,
      // isSubscriber: user.meta?.subscriptionStatus === 'trialing' ? false : user.meta?.isSubscribed,
      // subscriptionPlanName: user.meta?.subscriptionType,
      // isTrialling: user.meta?.subscriptionStatus === 'trialing',
      // createdAt: user?.joiningDate,
    }

    if (user.gender) {
      // @ts-expect-error Element implicitly has an 'any'
      info['Gender'] = user.gender === 'm' ? 'M' : 'F'
    }

    if (this.analytics) {
      setUserId(this.analytics, `${user.id}`)
    }

    window?.clevertap?.onUserLogin.push({ Site: info })

    window?.mixpanel?.identify(`${user.id}`)

    // const userTraits = {
    //   firstname: user.firstname,
    //   lastname: user.lastname,
    //   email: user.email,
    //   phone: user.phone,
    //   city: user?.city,
    //   state: user?.state,
    //   country: user?.country,
    //   isSubscriber: user.meta?.subscriptionStatus === 'trialing' ? false : user.meta?.isSubscribed,
    //   subscriptionPlanName: user.meta?.subscriptionType,
    //   isTrialling: user.meta?.subscriptionStatus === 'trialing',
    //   createdAt: user?.joiningDate,
    // }

    // const data = normalize(userTraits, 'snake')

    // for (const property in data) {
    //   if (['email', 'first_name', 'last_name', 'phone'].includes(property)) {
    //     window?.mixpanel?.people.set(`$${property}`, data[property])
    //   } else {
    //     window?.mixpanel?.people.set(property, data[property])
    //   }
    // }
  }

  unsubscribe(user: User) {
    const info = {
      Identity: user.id,
      Email: user.email,
      'MSG-email': false,
    }
    window?.clevertap?.onUserLogin.push({ Site: info })
  }

  track(event: TrackableEvent) {
    if (typeof window === 'undefined') {
      console.log(`Debug - ${event.evt} is not logged since it is server env`)
      return
    }

    const properties = event?.data ? event.data : {}

    if (this.isFirebaseEvent(event.evt) && this.analytics) {
      const rawFbProps: Record<string, any> = { ...properties } || {}
      const fbProps: Record<string, any> = {}
      const fbEvent = normalize(event.evt, 'snake')

      for (const fbPropKey in rawFbProps) {
        if (
          rawFbProps[fbPropKey] !== '' &&
          rawFbProps[fbPropKey] !== null &&
          rawFbProps[fbPropKey] !== undefined &&
          !Array.isArray(rawFbProps[fbPropKey])
        ) {
          fbProps[fbPropKey] = rawFbProps[fbPropKey]
        }

        if (Array.isArray(rawFbProps[fbPropKey]) && rawFbProps[fbPropKey].length > 0) {
          fbProps[fbPropKey] = rawFbProps[fbPropKey].join(',')
        }
      }

      if (fbProps && event.evt === 'purchase') {
        // fbProps.currency = 'EUR'
        // fbProps.value = 1.1
        // fbProps = normalize(fbProps, 'snake')

        const itemCategory = event.data.categories.length > 0 ? event.data.categories.join(',') : null

        const items = [
          {
            item_id: event.data.activityId,
            item_name: event.data.name,
            currency: 'EUR',
            item_brand: event.data.partner || undefined,

            price: event.data.price / 100,
            quantity: event.data.totalPersons,
          },
        ]

        if (itemCategory) {
          // @ts-expect-error not defined
          items[0].item_category = itemCategory
        }

        logEvent(this.analytics, 'purchase', {
          currency: 'EUR',
          value: event.data.value,
          transaction_id: event.data.transactionId,
          items,
        })
      } else {
        logEvent(this.analytics, fbEvent, normalize(fbProps, 'snake'))
      }

      // console.log(`Debug - FirebaseEvent ${event.evt} ==== properties`, JSON.stringify(fbProps, null, 2))
    }

    if (this.isCleverTapEvent(event.evt) && window?.clevertap) {
      // const { activity_categories, ...rest } = data
      const clvProps = { ...properties } || {}

      if (properties && properties?.categories && Array.isArray(properties.categories)) {
        clvProps.categories = properties.categories.join(',')
      }

      window.clevertap.event.push(event.evt, normalize(clvProps, 'snake'))

      // console.log(`Debug - CleverTap ${event.evt} ==== properties`, JSON.stringify(clvProps, null, 2))
    }

    if (this.isCleverTapEvent(event.evt) && window?.mixpanel) {
      window.mixpanel.track(event.evt, normalize(properties, 'snake'))
      // console.log(`Debug - mixpanel ${event.evt} ==== properties`, JSON.stringify(properties, null, 2))
    }
  }

  isFirebaseEvent(evt: string): boolean {
    const events = [
      'navigation',
      'login',
      'logout',
      'app_download_begin',
      'subscribe_to_newsletter',
      'activity_view',
      'purchase',
      'activity_summary',
      'sign_up_pii',
      'sign_up',
      'become_partner_create_activity',
      'become_partner_call',
      'contact_us',
      'gift_card_begin',
      // @TODO@REVIEW duplicating events for time being, need to remove above events later from firebase analytics
      'Page View',
      // 'User Logged In',
      'User Logged Out',
      'Activity Clicked',
      'Activity Viewed',
      // 'Booking Completed',
      'Date Selected',
      'More Dates Fetched',
      'Booking Summary Viewed',
      'Persons Updated',
      'Create Account Started',
      // 'User Registered',
      //
      'Popup Shown',
      'Newsletter Subscribed',
      'Location Changed',
      'See All Activities Clicked',
      'See All Hangouts Clicked',
      'Activity List Filtered',
      'Activity Searched',
      'Propose Hangout clicked',
      'Interests Updated',
    ]

    return events.includes(evt)
  }

  isCleverTapEvent(evt: string): boolean {
    const events = [
      'Page View',
      // 'User Logged In',
      'User Logged Out',
      'Activity Clicked',
      'Activity Viewed',
      // 'Booking Completed',
      'Date Selected',
      'More Dates Fetched',
      'Booking Summary Viewed',
      'Persons Updated',
      'Create Account Started',
      // 'User Registered',
      //
      'Popup Shown',
      'Newsletter Subscribed',
      'Location Changed',
      'See All Activities Clicked',
      'See All Hangouts Clicked',
      'Activity List Filtered',
      'Activity Searched',
      'Propose Hangout clicked',
      'Interests Updated',
    ]

    return events.includes(evt)
  }
}
