'use client'
import { Menu, Transition } from '@headlessui/react'
import cookieCutter from 'js-cookie'
import { useParams, usePathname, useRouter } from 'next/navigation'
import useTranslation from 'next-translate/useTranslation'
import { Fragment, useState } from 'react'

import Loader from '@/components/Loader'
import NextImage from '@/components/NextImage'

function LanguageChange() {
  const router = useRouter()
  const pathname = usePathname()
  const { t, lang: webLang } = useTranslation()
  const params = useParams()

  const lang = params?.lang || cookieCutter.get('NEXT_LOCALE') || 'es'

  const [selectedLang, setSelectedLang] = useState(lang)
  const [pageRefresh, setPageRefresh] = useState(false)

  const languages = [
    {
      value: 'es',
      label: (
        <div className="flex items-center justify-center">
          <NextImage
            className="h-6 w-6"
            width={24}
            height={24}
            src="https://img.icons8.com/color/48/000000/spain-circular.png"
            alt="Spanish flag"
          />
          <span className="hidden pl-1 font-medium xl:block">Esp.</span>
          <span className="pl-1 text-2xl font-medium xl:hidden">Español.</span>
        </div>
      ),
    },
    {
      value: 'en',
      label: (
        <div className="flex items-center justify-center">
          <NextImage
            className="h-6 w-6"
            width={24}
            height={24}
            src="https://img.icons8.com/color/48/000000/usa-circular.png"
            alt="USA flag"
          />
          <span className="hidden pl-1  font-medium xl:block">Eng.</span>
          <span className="pl-1 text-2xl font-medium xl:hidden">English</span>
        </div>
      ),
    },
  ]

  const handleChange = (e, option, close) => {
    e.preventDefault()

    const parts = pathname?.split('/') || []
    parts.splice(0, 2)

    cookieCutter.set('NEXT_LOCALE', option.value, { path: '/', expires: 365, sameSite: 'lax' })
    setPageRefresh(true)
    setSelectedLang(option.value)

    // router.refresh()
    // router.replace(`/${option.value}/${parts?.join('/')}`, { forceOptimisticNavigation: false })
    // close()
    window.location.href = `/${option.value}/${parts?.join('/')}`

    // window.location.href = `/` // @TODO Review
  }

  let currentLangOption = languages.find((item) => item.value === selectedLang)

  if (!currentLangOption) {
    currentLangOption = languages[0]
  }

  return (
    <div className="relative w-full min-h-[43px] min-w-[90px]">
      <Menu as="div" className="border-text-600 xl:absolute rounded-3xl border bg-white px-3 pb-2 pt-2">
        {({ open }) => (
          <>
            <Menu.Button className="flex w-full items-center justify-between">
              {pageRefresh ? <Loader /> : currentLangOption.label}
              {pageRefresh === false && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  transform={`rotate(${open ? '0' : '180'})`}>
                  <path
                    d="M19.3184 13.7148L13.629 8.02551C13.0432 7.43972 12.0935 7.43972 11.5077 8.0255L5.81836 13.7148"
                    stroke="#3D3D3D"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </Menu.Button>
            <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="mt-2 flex w-full flex-col divide-y divide-gray-100">
                {languages
                  .filter((x) => x.value != currentLangOption.value)
                  .map((language) => (
                    /* Use the `active` state to conditionally style the active item. */
                    <Menu.Item as={Fragment} key={language.value}>
                      {({ active, close }) => (
                        <div className="flex rounded-b-3xl bg-white py-1 text-black">
                          <a
                            onClick={(e) => handleChange(e, language, close)}
                            // className={`${active ? 'text-primary-700 bg-blue-500' : 'bg-white text-black'}`}
                          >
                            {language.label}
                          </a>
                          <span className="block h-7 w-7" />
                        </div>
                      )}
                    </Menu.Item>
                  ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

export default LanguageChange
