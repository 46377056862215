'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'

export const useNavigationEvent = (onPathnameChange: () => void) => {
  const pathname = usePathname() // Get current route
  const searchParams = useSearchParams()?.toString()
  // Save pathname on component mount into a REF
  const savedPathNameRef = useRef(pathname)
  const savedSearchParamsRef = useRef(searchParams)
  useEffect(() => {
    // If REF has been changed, do the stuff
    if (savedPathNameRef.current !== pathname) {
      onPathnameChange()
      // Update REF
      savedPathNameRef.current = pathname
    }

    if (savedSearchParamsRef.current !== searchParams) {
      onPathnameChange()
      // Update REF
      savedSearchParamsRef.current = searchParams
    }
  }, [pathname, searchParams, onPathnameChange])
}
