/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import ProgressBar from '@badrap/bar-of-progress'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import cookieCutter from 'js-cookie'
import { useSearchParams } from 'next/navigation'
import { useRef, useState } from 'react'

import { useLocalStorage } from '@/hooks/useLocalStorage'

import { AuthContextProvider } from '@/providers/AuthContext'
import { EventTrackingContext } from '@/providers/EventTrackingContext'
import { PageProgressContext } from '@/providers/PageProgressContext'
import { api } from '@/services/api'
import EventTracking from '@/services/tracking'
import * as utils from '@/utils'

import { AppLocale, LocationInfo } from '@/types'

export default function AppProviders({
  children,
  lang,
  isUserLoggedIn,
  locationSlug,
  locationInfo,
}: {
  children: React.ReactNode
  lang: AppLocale
  isUserLoggedIn: boolean
  locationSlug: string
  locationInfo: LocationInfo
}) {
  const searchParams = useSearchParams()
  const { setItem } = useLocalStorage()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: 1000 * 60 * 60 * 1, // 1 hour
          },
        },
      }),
  )

  const tracking = new EventTracking()

  const trackingRef = useRef(tracking)

  const progressBar = useRef(
    new ProgressBar({
      size: 4,
      color: '#A82137',
      delay: 0,
    }),
  )

  const accessToken = searchParams?.get('access_token')

  api.setLocale(lang)
  utils.setLocale(lang)

  const timeFormat = utils.detectMeridiem()
  utils.setMeridiem(timeFormat)
  cookieCutter.set('NEXT_TIME_FORMAT', timeFormat, { path: '/', expires: 365, sameSite: 'lax' })

  if (accessToken) {
    api.setAuthToken(accessToken)
    setItem('accessToken', accessToken)
  }

  const locationId = cookieCutter.get('NEXT_LOCATION_ID')

  if (locationId) {
    api.setLocationId(locationId)
  }

  if (locationInfo.id.toString() != locationId) {
    api.setLocationId(locationInfo.id)
  }

  const locationRadius = cookieCutter.get('NEXT_LOCATION_RADIUS')

  if (locationRadius) {
    api.setLocationRadius(locationRadius)
  }

  tracking.getFID().then((fid) => api.setFID(fid))

  // console.log(
  //   `provider init =============== lang = ${lang} === locationSlug ${locationSlug} === isUserLoggedIn = ${isUserLoggedIn} === locationId = ${locationId}`,
  // )

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider
        locationInfo={locationInfo}
        isUserLoggedIn={isUserLoggedIn}
        eventTracking={trackingRef.current}>
        <EventTrackingContext.Provider value={{ eventTracking: trackingRef.current }}>
          <PageProgressContext.Provider value={{ progressBar: progressBar.current }}>
            {children}
          </PageProgressContext.Provider>
        </EventTrackingContext.Provider>
      </AuthContextProvider>
    </QueryClientProvider>
  )
}
